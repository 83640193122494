<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
      @keydown.native.enter.exact.prevent.stop
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            prop="knowledge_group_id"
            :label="__('Knowledge Group')"
            class="is-required"
          >
            <el-select
              v-loading="knowledgeGroupLoading"
              v-model="
                nodeToBind.knowledge_distiller_node.data.knowledge_group_id
              "
              filterable
              @change="knowledgeGroupChange"
              :id="'knowledgeGroupSelect'"
              style="width: 100%"
            >
              <el-option
                v-for="group in knowledgeGroups"
                :key="group.knowledgeGroupId"
                :label="group.name"
                :value="group.knowledgeGroupId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            prop="prior_conversation_steps"
            :label="__('Prior Conversation Steps')"
          >
            <el-slider
              v-model="
                nodeToBind.knowledge_distiller_node.data
                  .prior_conversation_steps
              "
              show-input
              :max="maxAllowedSteps"
            >
            </el-slider>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            prop="query_text"
            :label="__('Query Text')"
            class="is-required"
          >
            <input-variable-popper
              v-model="nodeToBind.knowledge_distiller_node.data.query_text"
              force-reinitialize
              value="query_text"
            >
            </input-variable-popper>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="fetch_timeout" :label="__('Fetch Timeout')">
            <el-slider
              v-model="nodeToBind.knowledge_distiller_node.data.fetch_timeout"
              show-input
              :max="maxAllowedTimeout"
              :min="minAllowedTimeout"
            >
            </el-slider>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            prop="music_on_hold"
            :label="__('Music On Hold')"
            style="margin-top: 20px;"
            v-if="this.task_type && this.task_type === 'voice'"
          >
            <wav-file-uploader
              :folder="`tasks/${task_id}/knowledge_distiller_node/moh`"
              :file-url="musicOnHoldUrl"
              :file-name="musicOnHoldName"
              :max-size-in-mb="3"
              @on-success="handleMOHUploadSuccess"
              @on-delete="handleMOHFileRemove"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            prop="top_search_results"
            :label="__('Top Search Results (Top-K)')"
          >
            <el-slider
              v-model="
                nodeToBind.knowledge_distiller_node.data.top_search_results
              "
              show-input
              :max="maxAllowedResults"
            >
            </el-slider>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" style="padding-top: 10px">
        <el-col :span="24">
          <el-form-item
            :label="__('Assign Response to Variable')"
            prop="response_var"
            class="is-required"
          >
            <create-or-select
              :items="singleValuedVariables"
              label="variable_name"
              value="variable_id"
              :placeholder="__('Select or create a new variable')"
              :new-item-message="__('new variable')"
              :current_select="selectedVariableForResponse"
              @change="
                handleVariableChange($event)(
                  knowledgeDistillerVariableRuleValue
                )
              "
            />
            <el-col :span="4">
              <span
                class="row-message"
                v-if="isNewVariableCreatedToStoreResponse"
              >
                {{ __("new variable") }}
              </span>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="knowledge_distiller_node.data.no_match_canvas_id">
            <EventHandlers
              :event-handler-canvas="noMatchEventHandlerCanvas"
              :show-event-handler-count="false"
              :eventHandlerLabel="__('No Match Canvas')"
              :event-handler-place-holder="
                __('Select or create a no match event handler canvas')
              "
              @update-event-handler="setNoMatchEventHandlerCanvas($event)"
              cssClass="is-required"
            ></EventHandlers>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="knowledge_distiller_node.data.error_canvas_id">
            <EventHandlers
              :event-handler-canvas="errorEventHandlerCanvas"
              :show-event-handler-count="false"
              :eventHandlerLabel="__('Error Canvas')"
              :event-handler-place-holder="
                __('Select or create a error event handler canvas')
              "
              @update-event-handler="setErrorEventHandlerCanvas($event)"
              cssClass="is-required"
            ></EventHandlers>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            prop="llm_prompt"
            :label="__('GenAI Prompt')"
            class="is-required"
          >
            <el-select
              v-loading="knowledgePromptsLoading"
              v-model="nodeToBind.knowledge_distiller_node.data.llm_prompt"
              filterable
              @change="knowledgePromptChange"
              :id="'knowledgePromptSelect'"
              style="width: 100%"
              placeholder="Built In Knowledge Prompt"
            >
              <el-option
                :key="'built-in'"
                :label="'Built In Knowledge Prompt'"
                :value="null"
              ></el-option>
              <el-option
                v-for="group in knowledgePrompts"
                :key="group.promptId"
                :label="group.name"
                :value="group.promptId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import { NODE_TYPES } from "@/constants/nodes";
import _ from "lodash";
import __ from "@/translation";
import WavFileUploader from "@/components/uploaders/types/WavFileUploader.vue";
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect.vue";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper.vue";
import { eventHandlerNameValidation } from "@/utils/formValidationRules";
import { mapActions, mapGetters, mapState } from "vuex";
import EventHandlers from "@/views/build/callflow/components/node-type-forms/components/EventHandlers.vue";
import path from "path";

const variableRuleInitialize = {
  rule_value: "",
  variable_name: "",
  variable_id: -1,
  default_value: "",
  array_variable: false
};

export default {
  components: {
    EventHandlers,
    CreateOrSelect,
    WavFileUploader,
    InputVariablePopper
  },
  mixins: [BaseNode],
  data() {
    const validateResponseField = async (rule, value, callback) => {
      if (!this.selectedVariableForKnowledgeDistiller) {
        callback(new Error(__("Response Variable Required")));
      }
      callback();
    };
    const validateNoMatchEventHandler = (rule, value, callback) => {
      eventHandlerNameValidation(value, callback, this, "match");
    };
    const validateErrorEventHandler = (rule, value, callback) => {
      eventHandlerNameValidation(value, callback, this, "error");
    };
    return {
      rules: {
        knowledge_distiller_node: {
          data: {
            response_var: {
              validator: validateResponseField,
              trigger: "change"
            },
            no_match_canvas_id: {
              validator: validateNoMatchEventHandler,
              trigger: "change"
            },
            error_canvas_id: {
              validator: validateErrorEventHandler,
              trigger: "change"
            }
          }
        }
      },
      knowledgeDistillerVariableRuleValue: "open_ai_response_var",
      musicOnHoldName: "",
      musicOnHoldUrl: "",
      maxAllowedResults: 10,
      maxAllowedSteps: 5,
      maxAllowedTimeout: 30,
      minAllowedTimeout: 3,
      topSearchResults: 5,
      variableRuleInitialize: _.cloneDeep(variableRuleInitialize)
    };
  },
  computed: {
    ...mapGetters("variables", {
      singleValuedVariables: "singleValuedVariables"
    }),
    ...mapGetters("knowledgeAi", {
      knowledgePromptsLoading: "promptIsLoading",
      knowledgePrompts: "llm_prompts",
      knowledgeGroupLoading: "groupIsLoading",
      knowledgeGroups: "knowledgeGroups"
    }),
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      token: state => state.token,
      selectedAccountDomain: state => state.selectedAccountDomain
    }),
    noMatchEventHandlerCanvas() {
      return this.nodeToBind.knowledge_distiller_node.data.no_match_canvas_id;
    },
    errorEventHandlerCanvas() {
      return this.nodeToBind.knowledge_distiller_node.data.error_canvas_id;
    },
    /**
     * returns selected variable_id if selected from the dropdown or the name if it's a new variable for response field
     * @return string|number
     */
    selectedVariableForResponse() {
      let variableRule = this.insertedResponseVariableRule;
      const { variable_id, variable_name } = _.isEmpty(variableRule)
        ? this.variableRuleInitialize
        : variableRule;
      return variable_id === -1 ? variable_name : variable_id;
    },
    /**
     * returns selected variable rule for response variable rule if set, otherwise returns initial variable rule structure
     * @return object
     */
    insertedResponseVariableRule() {
      return (
        _.find(
          this.nodeToBind.knowledge_distiller_node.data.variable_rules.data,
          rule => rule.rule_value === this.knowledgeDistillerVariableRuleValue
        ) || this.variableRuleInitialize
      );
    },
    isNewVariableCreatedToStoreResponse() {
      if (_.isEmpty(this.insertedResponseVariableRule)) {
        return false;
      }
      return _.get(this.insertedResponseVariableRule, "msg", "") !== "";
    },
    isNewCanvasCreatedForNoMatchEventHandler() {
      if (_.isEmpty(this.noMatchEventHandlerCanvas)) {
        return false;
      }
      return _.get(this.noMatchEventHandlerCanvas, "msg", "") !== "";
    },
    musicOnHold: {
      get() {
        return this.nodeToBind.knowledge_distiller_node.data.music_on_hold;
      },
      set(path) {
        this.$set(
          this.nodeToBind.knowledge_distiller_node.data,
          "music_on_hold",
          path
        );
      }
    }
  },
  created() {
    this.fetchKnowledgeGroups();
    this.fetchPrompts();
    const eventHandlerCanvasInitialize = {
      canvas_name: "",
      canvas_id: -1,
      msg: ""
    };
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.knowledge_distiller_node)
    ) {
      this.$set(this.nodeToBind, "knowledge_distiller_node", {});
      this.$set(this.nodeToBind.knowledge_distiller_node, "data", {});
      this.$set(
        this.nodeToBind,
        "node_type",
        NODE_TYPES.KNOWLEDGE_DISTILLER.NODE_TYPE
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "music_on_hold",
        ""
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "knowledge_group_id",
        ""
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "llm_prompt",
        null
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "knowledge_group_name",
        ""
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "prior_conversation_steps",
        5
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "fetch_timeout",
        5
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "top_search_results",
        5
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "query_text",
        ""
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "no_match_canvas_id",
        _.cloneDeep(eventHandlerCanvasInitialize)
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "error_canvas_id",
        _.cloneDeep(eventHandlerCanvasInitialize)
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "variable_rules",
        {}
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data.variable_rules,
        "data",
        []
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "no_match_canvas_count",
        1
      );
    } else {
      if (
        this.nodeToBind.knowledge_distiller_node.data.no_match_canvas_id > 0
      ) {
        this.$set(
          this.nodeToBind.knowledge_distiller_node.data,
          "no_match_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize, {
            canvas_id: this.noMatchEventHandlerCanvas
          })
        );
        if (this.nodeToBind.knowledge_distiller_node.data.error_canvas_id > 0) {
          this.$set(
            this.nodeToBind.knowledge_distiller_node.data,
            "error_canvas_id",
            Object.assign({}, eventHandlerCanvasInitialize, {
              canvas_id: this.errorEventHandlerCanvas
            })
          );
        }
      }
      this.musicOnHoldUrl =
        this.nodeToBind.knowledge_distiller_node.data.music_on_hold_url || "";
      this.musicOnHoldName = this.getTailName(this.musicOnHoldUrl);
    }
  },
  methods: {
    ...mapActions("knowledgeAi", {
      getKnowledgePrompts: "getKnowledgePrompts",
      getKnowledgeGroups: "getKnowledgeGroups"
    }),
    async fetchKnowledgeGroups() {
      try {
        await this.getKnowledgeGroups();
      } catch (err) {
        console.error("Failed to fetch knowledge groups:", err);
      }
    },
    async fetchPrompts() {
      try {
        await this.getKnowledgePrompts("false");
      } catch (err) {
        console.error("Failed to fetch knowledge prompts:", err);
      }
    },
    /**
     * method to push passed rule_value(summary and transcript) with respective assigned variable
     * to variable_rules data for the node
     */
    knowledgeGroupChange(value) {
      const selectedGroup = this.knowledgeGroups.find(
        group => group.knowledgeGroupId === value
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "knowledge_group_name",
        selectedGroup.name
      );
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "knowledge_group_id",
        value
      );
    },
    knowledgePromptChange(value) {
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "llm_prompt",
        value
      );
    },
    handleVariableChange(option) {
      return rule_value => {
        this.newVariableCreated = option.value === -1;
        const insertedRowIdToVariableObj = {
          variable_id: option.value,
          variable_name: option.label,
          msg: option.msg,
          rule_value: rule_value,
          array_variable: false,
          default_value: {}
        };
        if (
          _.isEmpty(
            this.nodeToBind.knowledge_distiller_node.data.variable_rules
          )
        ) {
          this.$set(
            this.nodeToBind.knowledge_distiller_node.data,
            "variable_rules",
            {}
          );
          this.$set(
            this.nodeToBind.knowledge_distiller_node.data.variable_rules,
            "data",
            []
          );
        } else {
          _.remove(
            this.nodeToBind.knowledge_distiller_node.data.variable_rules.data,
            obj => obj.rule_value === rule_value
          );
        }
        this.nodeToBind.knowledge_distiller_node.data.variable_rules.data.push(
          insertedRowIdToVariableObj
        );
      };
    },
    handleMOHUploadSuccess({ uploaded_file_name, path, url }) {
      this.musicOnHoldName = uploaded_file_name;
      this.musicOnHoldUrl = url;
      this.musicOnHold = path;
    },
    handleMOHFileRemove() {
      this.musicOnHoldName = "";
      this.musicOnHold = "";
      this.musicOnHoldUrl = "";
    },
    setNoMatchEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "no_match_canvas_id",
        option
      );
      this.$refs.nodeForm.validateField(
        "knowledge_distiller_node.data.no_match_canvas_id"
      );
    },
    setErrorEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.knowledge_distiller_node.data,
        "error_canvas_id",
        option
      );
      this.$refs.nodeForm.validateField(
        "knowledge_distiller_node.data.error_canvas_id"
      );
    },
    cleanUpNodeToPrepareForSubmit() {
      // do necessary operations on a cloned version of nodeToBind obj
      // that return the nodeToBind object
      // in exactly the same way as an node object of this
      // type is returned from the backend

      // as I do not require any cleanup to do here in this particular case,
      // I am just sending back a cloned version of nodeToBind obj
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      // the manipulation to clean up the node
      // is moved to the above method. So we can
      // reassign the nodeToBind object as the object
      // that is returned from the cleanUpNodeToPrepareForSubmit method
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.createOrEditNode();
    },
    getTailName(p) {
      return _(_.split(path.basename(p), "_"))
        .tail()
        .value()
        .join("_");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/styles/node_common.scss";
.audio-player {
  min-width: 702px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;
    &:focus {
      border: 1px solid black;
    }
  }
}
</style>
